// @tailwind base;
@tailwind components;
@tailwind utilities;

$maincolor: #006FBA;
$subcolor: #FBA03D;
$gray: #C4C4C4;

$minDesktop: 768px;
$maxMobile: 767px;
$minSidebar: 1200px;

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img,
svg {
  display: inline-block;
}

body {
  // overflow: hidden;
  height: 100vh;
  background-color: #f5f2f1;
}

.calculette {
  // min-height: 100vh;
  // overflow-y: auto;

  .wrapper {
    display: flex;
    // flex-direction: row;
    height: 100%;
    // align-items: stretch;

    @media screen and (max-width:$minSidebar) {
      flex-direction: column;
    }
  }

  .jcsb {
    justify-content: space-between;
  }

  .flex-end {
    justify-content: flex-end;
  }

  .flex-start {
    align-items: flex-start;
  }

  .column {
    flex-direction: column;
  }

  .no-underline {
    text-decoration: none;
  }

  .por {
    position: relative;
  }

  .header2 {
    width: 250px;
    background-color: $maincolor;
    //border-right: 1px solid #ededed;
    padding: 1rem;
    height: 100vh;
    font-size: 24px;
    padding-top: 140px;
    order: 1;
    // flex: 0 0 250px;
    position: fixed;
    z-index: 999;
    flex: 1 0 10%;
    color: white;
    padding: 26px;

    .header-text {
      position: fixed;
      max-width: 200px;
      top: 150px;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;

      @media screen and (max-width:$minSidebar) {
        display: none;
      }
    }

    .logoHeader {
      position: fixed;
      bottom: 50px;
      width: 120px;
      left: 40px;

      @media screen and (max-width:$minSidebar) {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 75px;
        left: auto;
      }
    }

    @media screen and (max-width:$minSidebar) {
      width: 100vw;
      height: 50px;
      padding-top: 17px;

    }
  }

  .header2s {
    width: 250px;
    height: 100vh;
    display: none;

    @media screen and (max-width:$minSidebar) {
      display: block;
      width: 100vw !important;
      height: 50px;
      // border: 1px solid red;
      // z-index: 99999999999;

    }
  }

  .main-container {
    // overflow-y: auto;
    // border: 1px solid red;
    flex-grow: 1;
    padding-left: 250px;

    @media screen and (max-width:$minSidebar) {
      padding-left: 0px;
    }
  }
}

.bg-sub2 {
  background-color: $subcolor;
  color: white;
}

.text-sub2 {
  color: $subcolor
}

.text-normal {
  font-weight: normal;
}

.text-sub2 {
  color: $subcolor;
}

.mt-20-desktop {
  margin-top: 20px;

  @media screen and (max-width:$maxMobile) {
    margin-top: 0;
  }
}

.btn-aller-disable-web {
  border: 3px solid #e5e5e5;
  color: #fff;
  border-radius: 50px;
  font-size: 18px;
  background-color: #e5e5e5;
  padding: 10px 20px;
  cursor: not-allowed;
  font-weight: bold;
  line-height: 18px;

  & img {
    margin-left: 10px;
    display: inline-block
  }
}

.deco {
  width: 160px;
  max-width: 100%;
  background-image: url('https://hellio.com/hubfs/deco.svg');
  height: 80px
}

.logo-avis {
  position: absolute;
  top: 8px;
  right: 30px;
}

.default-header {
  @media screen and (min-width:$minSidebar) {
    position: fixed;
    max-width: 200px;
    top: 140px;

    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;

    }
  }

  @media screen and (max-width:$minSidebar) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: -7px;
    align-items: center;

    img {
      max-width: 100px;
      max-height: 25px;
      margin-top: -5px;
    }

    .logoHeaderDefault {
      position: relative;
    }

    p {
      color: white;
      font-size: 16px;
      margin-top: -4px;
    }
  }

  @media screen and (max-width:375px) {
    p {
      font-size: 14px;
      margin-top: -2px;

    }
  }
}

.calculette .btn-init {
  @media screen and (max-width:$minSidebar) {
    top: 10px
  }

}

.header-m {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.btn-aller,
.btn-init {
  line-height: 18px;
  z-index: 1;
}

.btn-init-m {
  background-color: transparent;
  padding: 0;
  margin: 10px auto 0 30%;
  border: 0;
}

.btn-main {
  line-height: 1em;
}

.navigation-general {
  position: fixed;
  padding-left: 70px;
  left: 250px;
  background: #f5f2f1;
  bottom: 0;
  padding-bottom: 40px;
  padding-top: 10px;
  padding-right: 70px;

  .wrap {
    display: flex;
    width: calc(100vw - 350px);
    justify-content: space-between;
  }

  @media screen and (max-width:$minSidebar) {
    left: 0;

    .wrap {
      width: 100vw;
      padding: 0 20px;
    }
  }
}

.navigation-mobile {
  padding: 10px 10px 0 10px;
  background-color: #F6F4F3;
  position: fixed;
  left: 0;
  bottom: 12px;
  width: 100%;
  color: white;
  justify-content: space-between;
  height: 90px;
}

.navigation-mobile-success {
  padding: 20px 10px 20px 0;
  background-color: #F6F4F3;
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  color: white;
}

.btn-nav {
  color: #c6c2be;
  font-size: 18px;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  cursor: pointer;
  transition: .2s;
  padding: 0;
  margin-right: 5px;
}

.btn-nav-m,
.btn-nav-m-disable {
  width: 100%;
  border-radius: 25px;
  padding: 0px 25px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: .2s;
  align-items: center;
  justify-content: center;
  border: 0;
  margin-bottom: 10px;
  min-height: 50px;

  svg {
    float: right;
    margin-top: 3px;
  }

  span {
    align-items: center;
    justify-content: center;
  }
}

.btn-nav-m {
  background-color: $subcolor;
}

.btn-nav-m-disable {
  background-color: $gray;
  cursor: not-allowed;
}

.col-m {
  @media screen and (max-width:$maxMobile) {
    flex-direction: column;

    >.radio-oval {
      margin: 5px auto;
      width: 100%;
    }
  }
}

.second {
  font-size: 0.5em;
}

p {
  font-size: 1em;
}

.gris-hellio {
  color: #3A3A3A;
}

.lh16 {
  line-height: 16px;
}

.lh22 {
  line-height: 22px;
}

.mt-20 {
  margin-top: 20px
}

.item-wrapper {
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width:$minSidebar) {
    margin-top: 20px;
    width: 872px;
  }

}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}

.primeBox {
  @media screen and (max-width:767px) {
    border: 1px solid #EDE9E6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 15px 25px;
    margin-top: 10px;
  }
}

.calculette .progress-bar-container .progress,
.calculette .progress-bar-container .progress-bar {
  @media screen and (max-width:$maxMobile) {
    height: 6px;
  }
}

.calculette .progress-bar-container {
  display: flex;

  .success-icon-check {
    float: right;
    margin-top: -10px;
    margin-left: 10px;

    @media screen and (max-width:$maxMobile) {
      margin-top: 0;
    }
  }
}

.calculette .progress-bar-container .progress {
  height: 5px;
  width: calc(100% - 0px);
}

.calculette .progress-bar-container .progress-bar {
  height: 5px;
}

.calculette .progress-bar-container p {
  font-size: 12px;
  width: 40px;
  margin-top: -13px;

  @media screen and (max-width:$maxMobile) {
    margin-top: -4px;
  }
}

.progress-reset {
  display: flex;
  width: 10%;
}

.progress-success-container {
  // padding-left: 30px;
  // width: 78%;
  width: 100%;

  @media screen and (max-width:$maxMobile) {
    padding-left: 0;
  }
}

.progress {
  @media screen and (max-width:$maxMobile) {
    margin-top: 10px;
  }
}

.content-container {
  position: relative;
}

.fadeIn {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.radio-rounded {
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
  padding: 5px;
  color: black;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  background-color: #fff;

  @media screen and (max-width:$maxMobile) {
    margin-right: 15px;
  }

  input {
    display: none;
  }

  &:hover {
    border: 1px solid $subcolor;
  }
}

.radio-rounded-checked,
.radio-oval-checked {
  color: white !important;
  border: 1px solid $subcolor !important;
}

.radio-rounded-checked {
  background-color: $subcolor;
}

.radio-oval-checked {
  background-color: $maincolor;

}

.radio-oval {
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 8px 14px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;

  input {
    display: none;
  }

  &:hover {
    border: 1px solid $maincolor;
  }
}

.item-container-m {
  width: 100%;
  padding: 0 10px;

  .item {
    // box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    border-radius: 7px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    background-color: #fff;
    font-size: 16px;
    min-height: 50px;

    span {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
    }

    // img {
    //   position: absolute;
    // }
  }

  .item-checked {
    border: 2px solid #FBA03D;
  }
}

.item-container {
  width: 200px;
  height: auto;
  font-size: 14px;
  margin-right: 22px;
  line-height: 15px;

  &:nth-child(4n) {
    margin-right: 0;
  }

  @media screen and (max-width:$maxMobile) {
    width: calc(50% - 10px);
    margin-right: 0;
  }

  @media screen and (max-width:830px) {
    width: 180px;
  }

  input {
    display: none;
  }

  label {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    height: 160px;
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 200px;
    text-align: center;
    height: 138px;
    border-radius: 5px;
    margin-bottom: 10px;
    background: white;


    img {
      height: 60px;
      width: 60px;
      margin-bottom: 5px;
    }

    @media screen and (max-width:$maxMobile) {
      width: 130px;
      height: 130px;

      img {
        max-width: calc(40vw - 20px);
      }
    }
  }

  @media screen and (max-width:$minSidebar) {
    margin-right: 0;

    .item {
      width: 180px;
      height: 130px;
    }
  }

  @media screen and (max-width:830px) {
    .item {
      width: 160px;
      height: 130px;
    }
  }

  .item-checked {
    border: 2px solid #FBA03D;
  }
}

.item-detail-container {
  width: 900px;
  height: auto;
  font-size: 14px;
  margin-right: 20px;

  @media screen and (max-width:$maxMobile) {
    width: 100%;
    margin-right: 0;
  }

  input {
    display: none;
  }

  label {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;

    @media screen and (max-width:$maxMobile) {
      width: 100%;
    }
  }

  .img-container {
    position: relative;
    display: flex;
    align-items: center;

    h1 {
      color: white;
      position: absolute;
      font-size: 24px;
      margin-left: 50px;
    }
  }

  .readmoreShadow {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 60px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    padding-top: 0px;
    // background: linear-gradient(to bottom, transparent, white);
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
    opacity: 0.8;
  }

  .item {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    border: 1px solid white;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    width: 430px;
    border-radius: 5px;
    transition: 0.3s;
    background-color: #fff;

    @media screen and (min-width:$minDesktop) {
      &:hover {
        border: 1px solid #3A3A3A;
      }
    }

    @media screen and (max-width:$maxMobile) {
      position: relative;
      width: 100%;
      padding: 15px;
      min-height: 10px;
    }

    p {
      font-size: 14px;
      color: #3A3A3A;
      line-height: 20px;
    }

    h2 {
      font-size: 18px;
      line-height: 30px;

      @media screen and (max-width:$maxMobile) {
        font-size: 14px;
      }
    }
  }

  .item-checked {
    border: 1px solid #FBA03D;
  }


  .radio,
  .radio-checked {
    float: right;
    height: 20px;
    width: 20px;
    vertical-align: middle;
    border-radius: 50%;
    border: 1px solid $subcolor;
    display: flex;
    align-items: center;
    justify-content: center;

    .radio-inner {
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }
  }

  .radio-checked {
    .radio-inner {
      background-color: $subcolor;
    }
  }

  .radio {
    .radio-inner {
      background-color: white;
    }
  }


}

.input-container {
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }


  input {
    border: 0;
    font-size: 14px;
    color: $subcolor;
    border-radius: 5px;
    padding: 7px;

    @media screen and (max-width:$maxMobile) {
      border: 1px solid #EDE9E6;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 0 10px;
      width: 100%;
      height: 30px;
    }

    &::placeholder {
      color: #C6C2BE;
      font-weight: 300;
    }

    &:focus {
      -webkit-appearance: none;
      outline: none;
    }
  }

  input[type=text] {
    width: 100%;
  }
}

.MaPrimeTag {
  font-size: 12px;
  color: white;
  padding: 3px 15px !important;
  font-weight: normal;
}




.square,
.square-checked {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  vertical-align: middle;
  border-radius: 2px;
}


.square-checked {
  background: $maincolor;
  border: 1px solid $maincolor;
  font-family: system-ui !important;
  line-height: 19px;
}

.square {
  border: 1px solid #C6C2BE;
}

.is-hidden {
  display: none;
}

.is-transparent {
  opacity: 0;
}

.whiteBoxWeb {
  width: 870px;
  border-radius: 5px;
  margin: auto auto;
  padding: 30px 10px 128px 10px;
  max-width: 100%;

  @media screen and (max-width:830px) {
    padding: 0 10px;
  }

  @media screen and (max-width:$maxMobile) {
    padding: 0px 0px 128px 0px;
    max-width: 95%;
    margin: 0px 2.5%;
  }

}

.whiteBoxSuccess {
  max-width: 920px;
  // border-radius: 5px;
  margin: auto auto;
  // background-color: #fff;
  position: relative;

  @media screen and (max-width:940px) {
    padding: 10px;
  }

  @media screen and (max-width:$maxMobile) {
    padding: 10px 0 0 0;
    margin: 0 10px 50px 10px;
  }
}


.flex-container {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width:$maxMobile) {
    flex-direction: column-reverse;
    align-items: stretch;
  }
}

.success-mobile-header {
  background-color: $subcolor;
  display: flex;
  border-radius: 0;
}

.success-mobile-header-img {
  width: 33%;
  background-size: cover;
  min-height: 200px;
  background-position: center;
  border-radius: 0 0 0 0;
}

.success-header-img {
  width: 33%;
  color: white;
  background-size: cover;
  // min-height: 133px;
  background-position: center;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 12px;
  min-height: 50px;
  line-height: 2em;

  @media screen and (max-width:$maxMobile) {
    width: auto;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    margin: -10px -20px 15px -20px;
    font-size: 10px;

    .btn-init-success {
      background-color: transparent;
      border: 0;
      margin-top: 3px;
    }
  }
}


.success-left-container-content {
  margin-top: 10px;

  .title-left {
    margin: 30px;

    @media screen and (max-width:$maxMobile) {
      margin: 15px 30px;
      font-size: 1.2em;
    }
  }

}

.success-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
  padding: 5px 0;

  h3 {
    font-weight: 400 !important;
    font-size: 14px;
  }

  h2 {
    font-size: 14px !important;
    font-weight: 200 !important;
  }
}

.success-item-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EDE9E6;
  padding-bottom: 10px;
  margin-bottom: 10px;

  .p {
    font-size: 12px;
    line-height: 16px !important;
    font-weight: 400 !important;
  }

  .h5-price {
    font-size: 28px;
    font-weight: 700;
  }

  .h5-prime {
    font-size: 28px;
    font-weight: 700;
    color: white;
    background-color: $maincolor;
    border-radius: 5px;
    padding: 3px 8px 5px 8px;
    width: max-content;
  }

  @media screen and (max-width:$maxMobile) {

    .h5-price,
    .h5-prime {
      font-size: 20px;
    }
  }

  .h5-prime-t {
    font-size: 24px;
    font-weight: 700;
    ;
    color: $maincolor;
  }

  &.last {
    border: 0
  }
}

.success-mobile-header-content {
  width: 67%;

  div {
    padding: 8px 20px;
    color: white;
  }

  h2 {
    line-height: 1em;
    font-weight: 500;
    font-size: 20px;
  }

  h3 {
    font-size: 14px;
    font-weight: 200;
  }
}

.success-left-container {
  background: white;
  color: #3A3A3A;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  // min-height: 500px;
  // height: 100%;
  border-radius: 5px;
  line-height: 18px;
  margin-right: 20px;
  width: 430px;

  @media screen and (max-width:$maxMobile) {
    width: auto;
  }

  .navSuccess {
    margin-top: auto;
  }

  h3 {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    margin-left: 30px;
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin-left: 30px;
  }

  @media screen and (max-width:$maxMobile) {
    order: 2;
    margin-right: 0;
  }
}


.success-right-container {
  padding: 10px 25px 20px 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // min-height: 500px;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 27px;
  width: 472px;

  @media screen and (max-width:$maxMobile) {
    margin-top: 0;
    width: auto;
  }

  .navSuccess {
    margin-top: auto;
  }

  .etre-rappele-box {
    display: flex;
    margin: 20px 0;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width:$maxMobile) {
      flex-direction: column-reverse;
      align-items: stretch;
      justify-content: space-evenly;
      height: 85px;
      margin: 10px 0;
    }
  }

  .solaire-h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  .p {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
  }


  h3 {
    font-weight: 700;
    font-size: 20px;
    color: $maincolor;

    @media screen and (max-width:$maxMobile) {
      font-size: 16px;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    color: #3A3A3A;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
  }

  h1 {
    font-weight: 700;
    font-size: 45px;
    line-height: 1em;
  }

  @media screen and (max-width:$maxMobile) {
    padding: 10px 20px;
    margin-bottom: 5px;


    h1 {
      font-size: 16px;
    }

    .resteAPayer {
      display: flex;
      align-items: center;

      h1 {
        flex-grow: 2;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .borne-list {
    list-style: none;

    li {
      padding: 5px 0
    }

    span {
      color: $maincolor;
      font-weight: bold;
      padding-right: 12px;
    }
  }

  .borne-block {
    background-color: rgba(237, 233, 230, 0.44);
    padding: 10px 30px;
    margin: 10px 0;

    h5 {
      font-size: 14px;
      color: rgba(58, 58, 58, 0.5)
    }

    h1 {
      font-size: 24px;
      line-height: 24px;
      color: $maincolor;
    }
  }


  .list-block {
    background-color: rgba(237, 233, 230, 0.44);
    padding: 10px 30px;
    margin: 10px 0;

    h5 {
      font-size: 14px;
      color: rgba(58, 58, 58, 0.5)
    }

    h1 {
      font-size: 45px;
      line-height: 45px;
      color: $maincolor;
    }
  }

  .fitContentRight {
    width: fit-content;
    float: right;
  }

  .paragraph {
    font-size: 10px;
    line-height: 12px;
    font-style: italic;
    color: #3A3A3A;
    font-weight: 200;
  }

  .successTitle {
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;
    color: $maincolor;
    display: inline-block;
    margin-right: 20px;

    @media screen and (max-width:$maxMobile) {
      font-size: 24px;
      line-height: 103%;
      width: 250px;
    }
  }
}

.success-right-container,
.success-left-container {
  @media screen and (max-width:$maxMobile) {
    min-height: auto;
  }
}

.success-title-container {
  display: flex;
  align-items: center;

  img {
    max-width: 50px;
  }
}

.img-success {
  width: 305px;
  background-color: blue;
  color: white;

  @media screen and (max-width:$maxMobile) {
    width: 100%;
  }
}



.bg-rose {
  background-color: #FFBBDC;
}

.bg-blue {
  background-color: #4CAFE6;
}

.w550 {
  width: 550px;
}

.greeting-container {
  max-width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 720px;

  @media (max-width: $maxMobile) {
    flex-direction: column;
    min-height: auto;
  }

  h3 {
    line-height: 28px;
    font-size: 24px;
    margin-bottom: 5px;
  }

  .paragraph {
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
  }

  .circle {
    width: 74px;
    height: 74px;

  }

  @media screen and (max-width:$maxMobile) {
    .circle {
      width: 40px;
      height: 40px;

    }

    h1 {
      display: none;
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      margin: 10px 0;
    }

    .paragraph {
      font-size: 12px;
    }
  }
}



.textarea-container {
  width: 300px;

  @media screen and (max-width:$maxMobile) {
    margin-top: -5px
  }
}

.is-textarea {
  border-radius: 5px;
  border: 2px solid #EDE9E6;
  width: 100%;
  height: 110px;
  resize: none;
  padding: 10px 20px;
}

.is-textarea::placeholder {
  color: #C6C2BE;
  font-size: 14px;
}

.text-light {
  font-size: 14px;
  font-weight: 200;
}

.btn-main-nohover {
  background-color: #006fba;
  color: white;
  border: 2px solid #006fba;
  border-radius: 30px;
  font-size: 18px;
  padding: 7px 20px;
}

.btn-underline {
  font-weight: bold;
  font-size: 14px;
  border: 0;
  background-color: transparent;
  text-decoration: underline;
  color: $maincolor;
}

.text-primary {
  color: $maincolor;
}

.info-block-container {
  position: relative;
  color: $maincolor;
  line-height: 1.5em;

  img {
    vertical-align: middle;
    margin-left: 10px;
  }

  .prime-name {
    font-size: 25px;

    @media screen and (max-width:$maxMobile) {
      font-size: 16px;
    }
  }

  span {
    vertical-align: middle;

    svg {
      margin-left: 10px;
    }
  }

  span.valign-sub {
    vertical-align: sub;

    svg {
      margin-left: 3px;
    }
  }

  .p {
    font-size: 28px;
    font-weight: bold;
    ;

    @media screen and (max-width:$maxMobile) {
      font-size: 20px;

      span {
        margin-left: 0
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

div.tooltip {
  background-color: $maincolor;
  max-width: 232px;
  padding: 12px 16px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;

  h2 {
    font-weight: bold;
    font-size: 18px;
  }
}

div.tooltip-bailleur {
  width: 300px;
}

.rotate180 {
  transform: rotate(180deg);
}

.vertical-borne-list {
  position: relative;

  .vertical-borne-list-item {
    margin-top: 10px;
    position: relative;
  }

  .list-bullet {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $maincolor;
    border-radius: 100px;
    font-weight: 700;
    color: white;
    line-height: 0;
  }

  .bullet-m {
    height: 16px;
    width: 16px;
    margin-right: 15px;
    margin-left: 2px;
  }

  .bullet-l {
    height: 20px;
    width: 20px;
    margin-right: 15px;
  }

  .list-item-l,
  .list-item-m {
    display: table-row;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    line-height: 0;
  }

  .list-item-l {
    h1 {
      font-size: 24px;
      margin-top: -3px;
      line-height: 26px;
      color: $maincolor;
    }

    p {
      line-height: 15px;
      margin-top: 15px;
      font-size: 12px;
      font-style: italic;
    }
  }

  .list-item-m {
    h1 {
      font-size: 15px;
      line-height: 20px;
      font-weight: 300;
      margin-top: -3px;
    }
  }

  .vline-main {
    background: $maincolor;
    z-index: 0;
    width: 1px;
    height: 95%;
    position: absolute;
    left: 9px;
  }

  // .vline-white {
  //   background: #fff;
  //   z-index: 0;
  //   top: 0px;
  //   width: 1px;
  //   height: 100%;
  //   position: absolute;
  //   left: 9px;
  // }
}

.calculette .inner-container {
  padding: 40px 5px 0 5px;

  @media screen and (max-width: $maxMobile) {
    padding: 0px 15px 0px 15px;
  }
}

.btn-main-hoverlarge {
  background-color: #006fba;
  color: white;
  border: 2px solid #006fba;
  border-radius: 30px;
  font-size: 18px;
  padding: 6px 15px;
  transition: 0.5s;
  line-height: 22px;

  &:hover {
    transform: scale(1.15);
  }
}


.btn-retour-success {
  border: 0;
  background-color: transparent;
  vertical-align: top;
  cursor: pointer;
  transition: .2s;
  margin-bottom: 5px;
  color: #3a3a3a;

  span {
    margin-left: 10px;
  }
}

.calculette .header {
  padding-top: 0 !important;
}

.btn-retour {
  height: 45px;
}

.btn-retour svg {
  margin: 0 15px;
  vertical-align: middle
}

.validate-error {
  background: #f13538;
  font-size: 10px;
  margin: 5px 0;
  color: white;
  width: fit-content;
  padding: 0 10px;
  border-radius: 3px;
}

.borneBox {
  border: 0.25px solid #006FBA;
  border-radius: 6px;
  padding: 15px;
  margin-top: 10px;
}

.react-calendar__navigation {
  margin-bottom: 0.5em;
}

.react-calendar .calendar-footer div:first-child {
  opacity: 0;
}

.react-calendar {
  border: 0;
  width: 300px;
}

.react-calendar-hr {
  border-color: #0070BA;
  margin: auto auto
}

.react-calendar__navigation__label {
  font-weight: 700;
  font-size: 14px !important;
  color: #3A3A3A;
  text-transform: capitalize;
}

.react-calendar__navigation button[disabled],
.react-calendar__tile:disabled {
  background-color: #fff;
}


.react-calendar__tile:disabled {
  color: #BEBEBE
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: $maincolor;
  color: white !important;
  border-radius: 5px;
}

.react-calendar button {
  font-size: 14px;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: black;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0 0 1em 0;
  font-weight: 300;
}

abbr[title] {
  text-decoration: none;
}


.creneaupicker-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  z-index: 9999999;
  margin: 0 !important;
  padding: 12px;

  @media screen and (max-width:$maxMobile) {
    // position:fixed;
    // bottom: 0;
    // height: 100vh;
    // width: 100vw;
    // top: auto;
    // display: auto;
    // margin: 0px;
    // background-color: rgba(0, 0, 0, 0);
    // height: auto;
    // border-radius: 0;

  }
}

.creneaupicker-box {
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  width: 100%;

  @media screen and (max-width:$maxMobile) {
    background-color: white;
    padding: 10px;
    margin: 0 5px;
    box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.4);

  }
}

.creneau-btn {
  color: $subcolor;
  background-color: #fff;
  border: 0;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  margin-top: 5px;
  padding: 0;
}

.ext-link,
.ext-link:link,
.ext-link:visited,
.ext-link:active,
.ext-link:hover {
  color: $subcolor;
  text-decoration: underline;
  cursor: pointer;
}

.btn-x-calendar {
  border: 0;
  background-color: #fff;
  cursor: pointer;
}

.creneau-btn-m {
  padding: 20px;
  height: 60px;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  color: $subcolor;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.final-container {
  max-width: 680px;
  margin: 30px auto 50px auto;
  text-align: center;

  h2 {
    font-size: 30px;
    font-weight: bold;
    color: $maincolor;
    margin-top: 30px;

    @media screen and (max-width:$maxMobile) {
      font-size: 16px;
    }
  }

  p {
    font-size: 14px;
    margin-top: 20px;

    @media screen and (max-width:$maxMobile) {
      font-size: 12px;
      margin-top: 10px;
    }
  }

}

.logoMarque {
  max-width: 200px;
  margin-top: 0px;
  max-height: 100px;

  @media screen and (max-width:$minSidebar) {
    max-width: 200px;
    max-height: 40px;
    margin-top: -13px;
  }
}

.logoMarqueContainer {
  width: 200px;
  height: 100px;

  @media screen and (max-width:$minSidebar) {
    max-width: 200px;
    height: 40px;
    display: block;
  }

}

.is-hidden-tablette {
  @media screen and (max-width:$minSidebar) {
    display: none;
  }
}

.only-show-tablette {
  @media screen and (min-width:$minSidebar) {
    display: none;
  }
}

.fz14 {
  font-size: 14px;
}

.fz18 {
  font-size: 18px;
}

.fw-600 {
  font-weight: 600;
}

.fw-300 {
  font-weight: 300;
}

.radio-align {
  margin-top: -1px;
  vertical-align: middle;
}

.flex-input {
  display: flex;

  input:not([type=checkbox]):not([type=radio]) {
    min-width: 300px;
  }

  .w-auto {
    min-width: auto
  }

  @media screen and (max-width:$maxMobile) {
    flex-direction: column;
    margin: 0 20px;
  }
}

.light-gray-hr {
  height: 1px;
  background-color: #EDE9E6;
  border: none;
}

.file-uploader-container {
  width: 424px;
  max-width: 100%;
  padding: 10px 30px 10px 30px;
  border: 1px dashed #C6C2BE;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  border-radius: 4px;

}

.uploader-paragraph {
  width: 100%;
  display: flex;
  justify-content: center;

  p {
    max-width: 288px;
    font-style: italic;
    font-size: 14px;
    min-height: 60px;
    text-align: center;
  }
}

.btn-file-uploader {
  background-color: transparent;
  color: $maincolor;
  text-decoration: underline;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
}

.flex-input2 {
  display: flex;
  align-items: center;

  @media screen and (max-width:$maxMobile) {
    flex-direction: column;
    align-items: stretch;
  }

}

.light-hr {
  border: 1px solid #EDE9E6;
}

.explain-items {
  display: flex;

  div {
    width: 270px;
  }

  @media screen and (max-width:$maxMobile) {
    flex-direction: column;
    align-items: center;

    div {
      width: 230px;
    }
  }

}


.m-center {
  @media screen and (max-width:$maxMobile) {
    display: flex;
    flex-direction: column;
  }
}

.no-border {
  border: 0;
}

.css-13cymwt-control {
  border-width: 0 !important;
}

.select-placeholder {
  color: #3A3A3A;
  font-size: 14px;
}


.popup-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  z-index: 99999999;
}

.popup-container {
  width: 500px;
  max-width: 90%;
  background-color: $maincolor;
  position: fixed;
  z-index: 999999999;
  margin-left: -50px;
  border-radius: 5px;
  color: white;
  padding: 40px 30px;
  text-align: center;

  @media screen and (max-width:600px) {
    margin-left: 0;
    left: 5%;
    right: 5%;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
  }

  p {
    font-size: 14px;
    font-weight: normal;
  }

  .btn-sub {
    font-weight: bold;
    border: 2px solid white;
    cursor: pointer;

    &:hover {
      border: 2px solid white;
    }
  }
}



.Q45-title {
  padding-bottom: 15px;
  margin-left: 10px;
  margin-top: 10px;

  img {
    width: 56px;
  }

  p {
    font-weight: 500;
  }

  @media screen and (max-width:$maxMobile) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    img {
      width: 30px;
    }

    p {
      margin-left: 10px;
    }
  }
}

.item-container-Q45 {
  width: 100%;
  padding: 0 10px;

  .item {
    // box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    padding: 2px 5px;
    border-radius: 7px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    background-color: #fff;
    font-size: 15px;
    min-height: 48px;
    cursor: pointer;
    margin-top: 5px;

    @media screen and (max-width:$maxMobile) {
      margin-top: 0;
    }

    span {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
    }
  }

  .item-checked {
    border: 2px solid #FBA03D;
  }
}

.codePromo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
  margin-top: 14px;

  @media screen and (max-width:$maxMobile) {
    margin-right: 0 !important;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .codePromo-item {
    width: 217px;
  }

  h1 {
    font-size: 14px;
    color: #3A3A3A;
    text-align: right;
    font-weight: normal;
    margin-bottom: 3px;
  }

  input {
    width: 175px;
    height: 37px;
    border: 1px solid #C6C2BE;
    border-radius: 5px;
    padding: 0 12px;
    font-size: 14px;
    background-color: #fff;

    &.valid {
      border: 1px solid $subcolor;
    }

    &.notvalid {
      border: 1px solid #F13538;
    }

    &::placeholder {
      color: #C6C2BE;
    }

    &:focus {
      outline: none;
    }
  }

  button {
    cursor: pointer;
    background: #C6C2BE;
    border-radius: 5px;
    width: 37px;
    height: 37px;
    border: 0;
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.valid {
      background-color: $subcolor;
    }
  }

  p {
    font-size: 10px;

    &.valid {
      color: $subcolor;

      a {
        color: $subcolor;
        text-decoration: underline;
        margin-left: 5px;
      }

    }

    &.notvalid {
      color: #F13538;

    }
  }

}

.solaire-card-container {
  .col {
    padding-right: 16px;

    @media screen and (max-width:$maxMobile) {
      display: flex;
      justify-content: center;
      padding-right: 0
    }
  }

  .col:last-child {
    padding-right: 0;
  }

  .solaire-card {
    background-color: #fff;
    text-align: center;

    @media screen and (max-width:$maxMobile) {
      margin-bottom: 20px;
      width: 217px;
    }

    .head {
      background-color: $subcolor;
      color: white;
      padding: 10px;
      border-radius: 4px 4px 0px 0px;

      h1 {
        font-weight: 700;
        font-size: 16px;
      }

      h2 {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .content {
      padding: 15px 0 20px;
      background-color: #fff;
      color: #3A3A3A;


      h1 {
        font-weight: 700;
        font-size: 24px;
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;

      }

      .prime {
        background: #006FBA;
        border-radius: 4px;
        padding: 5px 8px;
        width: fit-content;
        color: white;
      }
    }
  }


}



.checkbox-container {
  display: flex;
  align-items: flex-start;
  width: 600px;


  input {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    margin-top: -5px
  }

  label {
    font-size: 14px;
  }

}

.toggle-switch {
  margin-top: 5px;
  position: relative;
  display: inline-block;
  width: 37px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked+.slider {
    background-color: $subcolor;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
}

.badge {
  position: absolute;
  left: 270px;
}

.fz22 {
  font-size: 22px
}

.fz25 {
  font-size: 25px
}

.renoItem {
  width: 100%;

  label {
    padding: 4px 10px 4px 0;
    cursor: pointer;
  }

  h2 {
    font-size: 28px !important;
  }

  img {
    height: 25px;
  }

  @media screen and (max-width:$maxMobile) {
    h2 {
      font-size: 20px !important;
    }

    img {
      height: 18px;
    }
  }

  .selected {
    background: #DBF1FF;
    color: #006FBA !important;
  }

  .unselected {
    color: #EDE9E6;
  }

  .selected,
  .unselected {
    margin: 0 -25px;
    padding: 0 15px 0 25px;

    @media screen and (max-width:$maxMobile) {
      margin: 0 -20px;
      padding: 0 10px 0 20px;
    }
  }
}

.hr-reno {
  height: 1px;
  border: 0;
  color: black;
  background-color: #EDE9E6;
}

.cursor-pointer {
  cursor: pointer;
}

/*
 * Composants
 */

// Button

button.button {
  cursor: pointer;
  color: white;
  border-radius: 500px;
  height: 40px;
  font-size: 16px;
  padding: 0 24px;
  font-style: normal;
  font-weight: 600;
  border: 0;
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;

  svg,
  img {
    height: 1em;
  }

  &.main {
    background-color: $maincolor;
  }

  &.sub {
    background-color: $subcolor;
  }
}

// Page success (v2)
.success-v2 {
  height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);

  // upper lg
  @media screen and (min-width: $minDesktop) {
    height: 100vh;
    max-height: 100vh;
  }

  overflow: hidden;

  .success-container {
    padding: 0px 0px 40px;
  }
}

// HeaderDevis
.prime-small-text {
  .info-block-container .prime-name {
    font-size: 20px;
  }

  .code-promo {
    font-size: 20px;
  }
}

.header-devis {
  width: 100%;

  //h1 {
  //  font-size: 40px;
  //  font-weight: 700;
  //  line-height: 1em;
  //}
  //
  //p {
  //  text-align: left;
  //  font-family: Fieldwork;
  //  font-size: 14px;
  //  font-style: normal;
  //  font-weight: 600;
  //  line-height: 22px;
  //  margin-top: 10px;
  //}
}