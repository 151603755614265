.circle {
    border-radius: 50%;
    width: 87px;
    height: 87px;
}

.user_form_dialog .calculette {
    background-color: transparent!important;
}

.user_form_dialog h2 {
    margin-bottom: 16px!important;
}

.user_form_dialog .creneaupicker-bg {
    border-radius: 0!important;
}

.user_form_dialog label {
    font-size: 14px!important;
    line-height: 1.3;
}

.user_form_dialog .react-responsive-modal-modal {
    border-radius: 4px;
}